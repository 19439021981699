.Title {
  background: #2e1435;
  height: 180px;
  text-align: center;
  margin-left: -1%;
  font-size: 1rem;
}

.NavbarItems {
  background: #2e1435;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 75%;
  text-align: center;
  margin-left: 15%;
}

.navbar-logo {
  color: rgb(195, 0, 255);
  justify-self: center;
  margin-left: 0px;
  cursor: pointer;
}

.logo {
  width: 330px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 50px;
  list-style: none;
  text-align: left;
  justify-content: end;
  padding-top: 1rem;
  padding-left: 0rem;
  font-size: 25px;
}

.nav-links {
  color: rgb(221, 218, 218);
  text-decoration: none;
}

.nav-links-clicked {
  color: white;
  background-color: rgb(195, 0, 255);
  text-decoration: none;
  border-radius: 4px;
}

.nav-links:hover {
  color: white;
  background-color: rgb(195, 0, 255);
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
